.wrapper-class {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.editor-class {
    padding: 0 1rem 1rem 1rem;
    height: 200px;
    width: 90%
}

.toolbar-class {
    background-color: rgb(219, 214, 215);
}